import { PostInfo, WineRatingEntry, WineRatingEntryCreate } from './WineRatingSection';

export const getWineName = async (token: string): Promise<PostInfo> => {
  const response = await fetch(`/api/wine`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error('Failed to fetch wine name');
  }

  const data = await response.json();
  return data;
};

export const getWineRatings = async (token: string): Promise<WineRatingEntry[]> => {
  const response = await fetch(`/api/ratings`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    throw new Error('Failed to fetch wine ratings');
  }
  const data = await response.json();
  return data;
};

export const createWineRating = async (entry: WineRatingEntryCreate, token: string): Promise<WineRatingEntry> => {
  const response = await fetch(`/api/ratings`, {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entry),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Failed to create wine rating');
  }
  const data = await response.json();
  return data;
};

export const updateWineRating = async (entry: WineRatingEntry, token: string): Promise<WineRatingEntry> => {
  const response = await fetch(`/api/ratings/${entry.id}`, {
    method: 'PUT',
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(entry),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Failed to update wine rating');
  }
  const data = await response.json();
  return data;
};

export const deleteWineRating = async (id: number, token: string): Promise<void> => {
  const response = await fetch(`/api/ratings/${id}`, {
    method: 'DELETE',
    headers: {
      'Authorization': `Bearer ${token}`,
    },
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(errorData.detail || 'Failed to delete wine rating');
  }
};
