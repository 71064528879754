import React, { useEffect, useState } from 'react';
import {
  Modal,
  Button,
  Placeholder,
  Section,
  Input,
  List,
} from '@telegram-apps/telegram-ui';
import WineRatingItem from './WineRatingItem'; // Adjust the path as needed
import styles from './WineRatingSection.module.css';

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import {
  getWineRatings,
  createWineRating,
  updateWineRating,
  deleteWineRating,
  getWineName,
} from './apiService';

const starIcon = ({ ...props }) => (
  <svg
    width="14"
    height="14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7,0.81l2.05,4.07l4.62,0.65l-3.34,3.17l0.77,4.48L7,11.08L3.97,13.18L4.55,8.72L1.02,5.55l4.6-0.65L7,0.81z"
      fill="currentColor"
      opacity=".8"
    />
  </svg>
);

const parseAndValidateRating = (rating: string, alertFnc: (arg: string) => void) => {
  if (rating.trim() === '') {
    return 'N/A';
  }

  let ratingValue = parseFloat(rating);

  if (rating.length === 2 && !isNaN(ratingValue)) {
    ratingValue /= 10;
  }

  const decimalFormatError = rating.includes('.') && rating.length !== 3;
  if (
    isNaN(ratingValue) ||
    ratingValue < 0 ||
    ratingValue > 5 ||
    decimalFormatError
  ) {
    alertFnc('Оценка должна быть числом между 0.0 и 5.0 с шагом 0.1.');
    return;
  }

  return ratingValue.toFixed(1);
};

export type WineRatingEntry = {
  id: number; // Added id field
  name: string;
  rating: string;
  avatar: string;
  position: number;
};

export type PostInfo = {
  name: string | null;
  published: boolean;
};

// A type for creating a new entry without 'id'
export type WineRatingEntryCreate = {
  name: string;
  rating: string;
};

const PlaceholderImage = ({
  colorScheme,
}: {
  colorScheme: string;
}) => (
  <img
    alt="Вино"
    src={`./static/media/wineglass${
      colorScheme === 'dark' ? '-dark' : ''
    }.png`}
    className={styles.image}
  />
);

interface WineRatingSectionProps {
  token: string | null;
  colorScheme: string;
}

export const WineRatingSection: React.FC<WineRatingSectionProps> = ({ token, colorScheme }) => {
  const [names, setNames] = useState<WineRatingEntry[]>([]);
  const [editingEntry, setEditingEntry] = useState<{
    id: number;
    rating: string;
  } | null>(null);
  const [wineName, setWineName] = useState<string | null>(null);
  const [isPublished, setIsPublished] = useState<boolean>(true);
  const listRef = React.useRef<HTMLDivElement>(null);

  const alertFnc = (text: string) => {
    toast.error(text, {
      position: "top-center",
      autoClose: 2000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: colorScheme,
      onClose: () => toast.clearWaitingQueue(),
      });
    } 

  // Fetch wine name
  useEffect(() => {
    const fetchWineName = async () => {
      try {
        if (token) {
          const info = await getWineName(token);
          setWineName(info.name);
          setIsPublished(info.published);
        }
      } catch (error) {
        console.error('Error fetching wine name:', error);
        setWineName(null);
        setIsPublished(true);
      }
    };

    fetchWineName();
  }, [token]);

  useEffect(() => {
    // Fetch initial data
    const fetchData = async () => {
      try {
        if (token) {
          const data = await getWineRatings(token);
          const sortedData = data.sort((a, b) => a.position - b.position);
          setNames(sortedData);
          //setNames(data);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        alertFnc('Ошибка при загрузке данных');
      }
    };

    fetchData();
  }, [token]);

  const addName = async (newEntryData: WineRatingEntryCreate) => {
    try {
      if (token) {
        const createdEntry = await createWineRating(newEntryData, token);
        setNames([...names, createdEntry]);
      }
    } catch (error) {
      console.error('Error adding name:', error);
      alertFnc('Ошибка при добавлении участника');
    }
  };

  const updateName = async (
    id: number,
    updatedFields: Partial<WineRatingEntry>
  ) => {
    try {
      if (token) {
        const existingEntry = names.find((entry) => entry.id === id);
        if (existingEntry) {
          const updatedEntry = { ...existingEntry, ...updatedFields };
          const response = await updateWineRating(updatedEntry, token);
          setNames(
            names.map((entry) =>
              entry.id === id ? response : entry
            )
          );
        }
      }
    } catch (error) {
      console.error('Error updating name:', error);
      alertFnc('Ошибка при обновлении оценки');
    }
  };

  const deleteName = async (idToDelete: number) => {
    try {
      if (token) {
        await deleteWineRating(idToDelete, token);
        setNames(names.filter((entry) => entry.id !== idToDelete));
      }
    } catch (error) {
      console.error('Error deleting name:', error);
      alertFnc('Ошибка при удалении участника');
    }
  };

  const ModalWithTrigger = () => {
    const [name, setName] = useState('');
    const [rating, setRating] = useState('');

    const handleAddName = () => {
      const trimmedName = name.trim();
      if (!trimmedName) {
        alertFnc('Нужно ввести имя!');
        return;
      }

      // Check for duplicate names (Unique Name Requirement)
      const nameExists = names.some(
        (entry) => entry.name.toLowerCase() === trimmedName.toLowerCase()
      );

      if (nameExists) {
        alertFnc('Такое имя уже есть в списке!');
        return;
      }

      const ratingValue = parseAndValidateRating(rating, alertFnc);
      if (ratingValue === undefined) {
        return;
      }

      const newEntryData: WineRatingEntryCreate = {
        name: trimmedName,
        rating: ratingValue,
      };

      addName(newEntryData);
      setName('');
      setRating('');
    };

    return (
      <Modal
        header={<Modal.Header />}
        trigger={
          <Button size="l" stretched>
            Новый участник
          </Button>
        }
        className={styles.modal}
        nested={true}
      >
        <Placeholder
          description=""
          action={
            <Button onClick={handleAddName} size="m" stretched>
              Добавить
            </Button>
          }
        >
          <div className={styles.modalContent}>
            <Input
              placeholder="Имя"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <Input
              type="number"
              placeholder="Оценка"
              value={rating}
              onChange={(e) => setRating(e.target.value)}
            />
          </div>
        </Placeholder>
      </Modal>
    );
  };

  const handleEditClick = (entry: WineRatingEntry) => {
    if (isPublished) {
      return;
    }
    if (editingEntry !== null) {
      // Save edits to the previous card
      handleSaveEdit();
      return;
    }
    
    // Now set up editing the new card
    setEditingEntry({
      id: entry.id,
      rating: entry.rating === 'N/A' ? '' : entry.rating ?? '',
    });
  };

  const handleSaveEdit = () => {
    if (editingEntry && !isPublished) {
      const ratingValue = parseAndValidateRating(editingEntry.rating, alertFnc);
      console.log(ratingValue);
      if (ratingValue === undefined) {
        return;
      }
      updateName(editingEntry.id, { rating: ratingValue });
      setEditingEntry(null);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
      if (
        editingEntry &&
        listRef.current &&
        !listRef.current.contains(event.target as Node) &&
        !isPublished
      ) {
        console.log("Click.Outside");
        handleSaveEdit();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [editingEntry]);

  return (
    <Section header={wineName || "Оценки по системе Vivino"}>
      <Placeholder
        header={names.length === 0 ? 'Редактор оценок' : ''}
        description={!isPublished || names.length === 0 ? 'Добавляйте имена и назначайте оценки.' : ''}
        action={isPublished ? '' : <ModalWithTrigger />}
        className={styles.section}
      >
        {names.length === 0 && (
          <PlaceholderImage colorScheme={colorScheme} />
        )}
        <div ref={listRef}>
        <List>
          {names
            .slice()
            .sort((a, b) => a.position - b.position)
            .map((entry) => (
            <WineRatingItem
              key={entry.id} // Use id as key
              entry={entry}
              editingEntry={editingEntry}
              colorScheme={colorScheme}
              starIcon={starIcon}
              handleEditClick={handleEditClick}
              handleSaveEdit={handleSaveEdit}
              deleteName={deleteName}
              setEditingEntry={setEditingEntry}
              isPublished={isPublished}
            />
          ))}
        </List>
        </div>
      <ToastContainer limit={1} closeButton={false}/>
      </Placeholder>
    </Section>
  );
};

export default WineRatingSection;
