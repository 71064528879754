import React from 'react';

import { useEffect, useState } from 'react';
import { AppRoot, List } from '@telegram-apps/telegram-ui';
// import { CellSection } from './components/CellSection';
// import { FormSection } from './components/FormSection/FormSection';
// import { BannerSection } from './components/BannerSection';
// import { TimelineSection } from './components/TimelineSection/TimelineSection';
// import { TooltipSection } from './components/TooltipSection/TooltipSection';
// import { ModalSection } from './components/ModalSection/ModalSection';
import { WineRatingSection } from './components/WineRatingSection/WineRatingSection';

export const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null);
  const [token, setToken] = useState<string | null>(null);  // Store the token
  const [colorScheme, setColorScheme] = useState(
    window.Telegram.WebApp.colorScheme ?? 'light'
  );
  useEffect(() => {
    const updateTheme = () =>
      setColorScheme(window.Telegram.WebApp.colorScheme ?? 'light');

    updateTheme();
    window.Telegram.WebApp.onEvent?.('themeChanged', updateTheme);

    return () => {
      window.Telegram.WebApp.offEvent?.('themeChanged', updateTheme);
    };
  }, []);

  useEffect(() => {
    const authenticate = async () => {
      if (window.Telegram?.WebApp) {
        const initData = window.Telegram.WebApp.initData;
        const urlParams = new URLSearchParams(window.location.search);
        const startParam = urlParams.get('startapp');

        if (!initData || !startParam) {
          console.error('Authentication data not found.');
          setIsAuthenticated(false);
          return;
        }

        try {
          const response = await fetch('/auth', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({ initData, startParam })
          });

          const data = await response.json();

          if (response.ok && data.success) {
            // Authentication successful
            setIsAuthenticated(true);
            setToken(data.token);  // Save the token
          } else {
            // Authentication failed
            console.error('Authentication failed:', data.error);
            setIsAuthenticated(false);
          }
        } catch (error) {
          console.error('Error during authentication:', error);
          setIsAuthenticated(false);
        }
      } else {
        console.error('Telegram WebApp is not available.');
        setIsAuthenticated(false);
      }
    };

    authenticate();
  }, []);

  if (isAuthenticated === null) {
    // Authentication in progress
    return <div>Authenticating...</div>;
  }

  if (!isAuthenticated) {
    // Authentication failed
    return <div>Authentication failed. Please try again.</div>;
  }

  // Authentication successful, render the app
  return (
    <AppRoot>
      <List>
        <WineRatingSection 
          token={token}
          colorScheme={colorScheme}
        />
      </List>
    </AppRoot>
  );
};
