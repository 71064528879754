import React, { useState, ReactNode } from "react";
import { useSwipeable } from "react-swipeable";
//import { FaTrashAlt } from 'react-icons/fa';
import { FaEyeSlash } from 'react-icons/fa';

interface SwipeableCardProps {
  onDelete: (e: React.MouseEvent<HTMLDivElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
  children: ReactNode;
  buttonRef: React.RefObject<HTMLDivElement>;
  isSwipeDisabled?: boolean; // New prop to disable swiping
}

const MAX_TRANSLATE_X = -70; // Maximum left swipe distance
const SNAP_THRESHOLD = -35; // Threshold to decide snapping direction
const SMOOTHING_POINTS = 4; // Number of points to use for smoothing
const VELOCITY_THRESHOLD = 0.1; // Minimum velocity to switch direction
const SMOOTHING_FACTOR = 0.7; // Exponential smoothing factor
const START_THRESHOLD = -30; // When start moving

const SwipeableCard: React.FC<SwipeableCardProps> = ({ 
  onDelete,
  onClick,
  children,
  buttonRef,
  isSwipeDisabled = false,
}) => {
  const [translateX, setTranslateX] = useState(0);
  const [deltaXBuffer, setDeltaXBuffer] = useState<number[]>([]); // Buffer for smoothing
  const [currentDirection, setCurrentDirection] = useState<string | null>(null);;

  const updateBuffer = (deltaX: number) => {
    // Add the new value to the buffer and keep only the last `SMOOTHING_POINTS` values
    setDeltaXBuffer((prev) => [...prev.slice(-SMOOTHING_POINTS + 1), deltaX]);
  };

  const calculateSmoothedPosition = () => {
    // Calculate the average of the buffer for smoothing
    if (deltaXBuffer.length === 0) return translateX;
    return (
      deltaXBuffer.reduce((sum, value) => sum + value, 0) /
      deltaXBuffer.length
    );
  };
  
  // const [isSwiped, setIsSwiped] = useState(false);

  const handlers = useSwipeable({
    onSwiping: (eventData) => {

      if (isSwipeDisabled) return;
      // Allow vertical swipes (absY > absX) to propagate
      // if (eventData.absY > eventData.absX) {
      //  return; // Do nothing for vertical swipes; let them propagate
      //}

      let deltaX = eventData.deltaX;

      deltaX = Math.max(MAX_TRANSLATE_X, Math.min(0, deltaX)); // Clamp deltaX between MAX_TRANSLATE_X and 0

      const velocity = eventData.vxvy[0];
      const newDirection = eventData.dir;

      if (
        Math.abs(velocity) > VELOCITY_THRESHOLD &&
        newDirection !== currentDirection
      ) {
        // Direction has changed significantly
        setCurrentDirection(newDirection);
        setDeltaXBuffer([]); // Reset buffer on direction change
        setTranslateX(deltaX); // Immediately set translateX to current deltaX
      } else {
        updateBuffer(deltaX);

        const smoothedTranslateX = calculateSmoothedPosition();

        // Apply exponential smoothing to the position to create smooth motion
        const smoothedPosition =
          translateX * (1 - SMOOTHING_FACTOR) +
          smoothedTranslateX * SMOOTHING_FACTOR;

        // Ensure that the intention is really swipe
        if (smoothedPosition < START_THRESHOLD) {
          setTranslateX(smoothedPosition);
        }
      }
    },
    onSwipedLeft: () => {
      if (isSwipeDisabled) return;
      setTranslateX(MAX_TRANSLATE_X); // Fully reveal delete area
      //setIsSwiped(true);
    },
    onSwipedRight: () => {
      if (isSwipeDisabled) return;
      setTranslateX(0); // Hide delete area
      //setIsSwiped(false);
    },
    onSwiped: () => {
      if (isSwipeDisabled) return;
      // Fallback for incomplete swipes
      if (translateX <= SNAP_THRESHOLD) {
        setTranslateX(MAX_TRANSLATE_X);
      } else {
        setTranslateX(0);
      }
      setDeltaXBuffer([]); // Clear the buffer after snapping
      setCurrentDirection(null); // Reset direction
    },
    onTap: (eventData) => {
      if (translateX === 0) {
        if (onClick) {
          const mouseEvent = eventData.event as MouseEvent; // Extract the native event
          console.log(mouseEvent.target);
          //const target = mouseEvent.target as HTMLElement;
          //if (target && typeof (target as any).click === 'function') {
          //  (target as HTMLButtonElement).click();
          //  
          //}

          //onClick(mouseEvent as unknown as React.MouseEvent<HTMLDivElement>); // Cast to React's MouseEvent

          if (buttonRef.current && buttonRef.current.contains(mouseEvent.target as Node)) {
            //const targetElement = mouseEvent.target as HTMLElement;

            //if (targetElement.click) {
              //targetElement.click(); // Fire the click event on the inner target
              //targetElement.dispatchEvent(mouseEvent);
            return;
            //}
          }
          /*if (buttonRef.current && buttonRef.current.contains(mouseEvent.target as Node)) {
            const childButton = buttonRef.current.querySelector('button'); // Adjust selector if needed

            if (childButton) {
              childButton.click(); // Trigger the click event on the child
            }
            return;
          }*/
          onClick(mouseEvent as unknown as React.MouseEvent<HTMLDivElement>); // Cast to React's MouseEvent
        }
      }
    },
    preventScrollOnSwipe: false,
    trackMouse: true,
  });

  return (
    <div
      style={{
        position: "relative",
        width: "100%",
        overflow: "hidden",
        borderRadius: "4px",
        marginBottom: "16px",
      }}
    >
      {/* Delete Area */}
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          height: "100%",
          width: "70px",
          background: "var(--tg-theme-destructive-text-color)",
          color: "#fff",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={onDelete}
      >
        <FaEyeSlash size={28}/>
      </div>

      {/* Main Card Content */}
      <div
        style={{
          position: "relative",
          transform: `translateX(${translateX}px)`,
          transition: "transform 0.2s ease",
          background: "var(--tgui--bg_color)",
        }}
        {...handlers}
      >
        <div>{children}</div>
      </div>
    </div>
  );
};

export default SwipeableCard;
